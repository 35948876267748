<template>
  <div>
    <!-- <pre>{{phoneNumber}}</pre> -->
    <alert-dialog :dialog="alertDialog" :title="alertTitle" :text="alertText" @close="alertDialog = false" />
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            <v-select
              v-model="phoneNumber"
              :items="instances"
              item-text="label"
              item-value="value"
              outlined
              :label="`${$t('channels.channel')} *`"
              hide-details
              required
              class="mb-3"
              attach
            ></v-select>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            <v-text-field v-model="eFormTitle" :label="`${$t('eform.title')} *`" outlined></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            <v-text-field
              v-model="eFormFacebookPixel"
              required
              label="Pixel ID"
              :prepend-inner-icon="icons.mdiCounter"
              outlined
              hide-details
              class="mb-3"
              type="number"
              placeholder="1236776XXX"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            <v-text-field v-model="eFormDescription" :label="`${$t('eform.description')} *`" outlined></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            <v-text-field
              v-model="eFormGoogleTagManager"
              required
              label="Tag Manager ID"
              :prepend-inner-icon="icons.mdiPoundBoxOutline"
              outlined
              placeholder="GTM-123XXX"
              class="mb-3"
              :hint="$t('LinkGenerator.tutorialSlugFour')"
              persistent-hint
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            <v-checkbox
              v-model="eFormAddToQuickReplay"
              class="mb-3"
              color="primary"
              :label="`${$t('eform.addToQuickReplay')}`"
            >
            </v-checkbox>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="12">
            <survey-creator-new @save-survey="saveSurvey" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import {
  mdiFormSelect,
  mdiInformationOutline,
  mdiPlus,
  mdiRadioTower,
  mdiCounter,
  mdiImageFilterCenterFocusWeak,
  mdiPoundBoxOutline,
  mdiPlusCircle,
} from '@mdi/js'
import PouchDB from 'pouchdb-browser'
import eFormMixin from '@/mixins/eFormMixin'
import SurveyCreatorNew from '@/components/surveyjs/SurveyCreatorNew.vue'
import AlertDialog from '@/components/dialog/AlertDialog.vue'
import pouchDbMixin from '../../mixins/pouchDbMixin'
import contactMixin from '../../mixins/contactMixin'

PouchDB.adapter('worker', require('worker-pouch'))

export default {
  name: 'CreateEform',
  components: {
    SurveyCreatorNew,
    AlertDialog,
  },

  mixins: [eFormMixin, pouchDbMixin, contactMixin],

  setup() {
    return {
      icons: {
        mdiFormSelect,
        mdiInformationOutline,
        mdiPlus,
        mdiRadioTower,
        mdiCounter,
        mdiImageFilterCenterFocusWeak,
        mdiPoundBoxOutline,
        mdiPlusCircle,
      },
    }
  },

  data() {
    return {
      phoneNumber: '',
      dataEdit: false,
      createSuccess: false,
      contactListSelected: {},

      availableFields: [],
      eFormTitle: this.$t('eform.titlePlaceholder'),
      eFormDescription: this.$t('eform.descriptionPlaceholder'),
      eFormFacebookPixel: null,
      eFormGoogleTagManager: null,
      eFormAddToSurvey: false,
      eFormAddToQuickReplay: false,
      eFormSelectedFields: [],

      // alert
      alertDialog: false,
      alertTitle: null,
      alertText: null,
      contactListTable: [],
      listContactValue: [],
      labelData: [],
      contactListSetting: null,

      currentContactList: {},
      currentSavedReplies: [],
    }
  },

  computed: {
    instances() {
      const listInstance = []
      this.$store.getters['auth/getListInstance'].value.forEach(instance => {
        listInstance.push({
          label: `${instance.label} - (${instance.phone_number})`,
          value: instance.phone_number,
        })
      })

      return listInstance
    },
    allSettings() {
      return this.$store.getters['global/getSettings']
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
  },
  async mounted() {
    await this.loadSetting(true)
    this.getSavedReplies()
    const idlist = this.$route.params.idlist
    if (idlist) {
      this.getContactListById(idlist)
    }

    this.$eventBus.$on(process.env.VUE_APP_EVENT_UPDATE_SETTINGS, async payload => {
      if (payload.id === 'contact_list') {
        await this.$store.dispatch('global/setSettingsById', payload)
        this.replicateFromPouchDbGlobal(process.env.VUE_APP_DB_SETTING_NAME, this.user, async data => {
          await this.loadSetting(true)
          await this.loadTable()
        })
      }
    })
  },

  methods: {
    async loadSetting(isExist = false) {
      if (!isExist) {
        await this.loadSettings()
      }
      const res = await this.loadSettingContactsList(this.allSettings)
      this.labelData = res.labelData
      this.contactListSetting = res.contactListSetting
      this.listContactValue = res.listContactValue

      this.deleteUnusedContact(this.contactListTable, this.listContactValue)
    },
    async saveSurvey(survey) {
      if (JSON.stringify(survey) === JSON.stringify({ pages: [{ name: 'page1' }] })) {
        this.alertDialog = true
        this.alertTitle = 'Oops...'
        this.alertText = 'survey is empty'
      } else if (this.eFormTitle && this.phoneNumber) {
        const eFormId = this.$nanoid();
        const newColumns = this.currentContactList.columns;
        newColumns.push({
          id: newColumns.length + 1,
          name: 'answerData',
          align: 'left',
          label: 'Answer Data',
          field: 'answerData',
          value: 'answerData',
          text: 'Answer Data',
          sortable: true,
          type: 'text',
          show: true,
          showable: true,
          formatDate: '',
          deleteable: true,
          isRequired: true,
          defaultValue: '',
          editable: true,
          order_number: newColumns.length + 1,
        })
        const data = {
          title: this.eFormTitle,
          description: this.eFormDescription,
          facebook_pixel_id: this.eFormFacebookPixel,
          google_tag_manager_id: this.eFormGoogleTagManager,
          channel: this.phoneNumber,
          addToSurvey: this.eFormAddToSurvey,
          addToQuickReplayMenu: this.eFormAddToQuickReplay,
          selectedFields: [],
          contactList: this.currentContactList.contacts,
          survey,
          created_at: Date.now(),
          fromSystem: false,
          idlist: this.$route.params.idlist,
          columns: newColumns,
          eFormId
        }

        await this.createEForm(data)
        if (this.createSuccess) {
          if (this.eFormAddToQuickReplay) {
            this.currentSavedReplies.push({
              id: this.$nanoid(),
              tag: 'Add E-Form',
              showText: `Add this contact to ${this.eFormTitle}`,
              text: 'add_eform',
              isEform: true,
              eFormId,
              listId: this.$route.params.idlist,
              channelId: this.phoneNumber,
            })
            this.currentSavedReplies.push({
              id: this.$nanoid(),
              tag: 'Send E-Form',
              showText: `Send ${this.eFormTitle} form to this contact`,
              text: `https://enterprise.marketa.id/e-form/survey/${this.user.sub_id+'-'+eFormId}`,
              isEform: true,
              channelId: this.phoneNumber,
            })
            await this.$store.dispatch('global/updateSettingsByKey', {
              sub_id: this.user.sub_id,
              token: this.user.token,
              key: 'saved_replies',
              value: this.currentSavedReplies
            })
          }
          await this.loadSetting()
          this.$router.push({ name: 'e-form' }, () => {})
        }
      } else {
        this.alertDialog = true
        this.alertTitle = 'Oops...'
        this.alertText = this.$t('eform.titleEmpty')
      }
    },
    async getContactListById(idlist) {
      const { data } = await this.$store.dispatch('global/getContactLists', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        list_id: idlist,
      })
      this.currentContactList = data
    },
    async getSavedReplies() {
      const { data } = await this.$store.dispatch('global/getSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'saved_replies',
      })
      this.currentSavedReplies = data.value
    },
  },
}
</script>
